<template>
<div>
    <div>
       <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
            <div>
            <b-card-title>{{$t('curlang')=='en'?'Validate Images':'Validar Imagenes'}}</b-card-title>
           </div>
     
            <div class="d-flex">
                <b-form-group>
               <v-select
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="options"
          :searchable="false"
        />
                 </b-form-group>
            <b-form-group class="ml-1">
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            :placeholder="$t('curlang')=='en'?'Filter by Search':'Filtrar por Busqueda'"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              {{this.$t('curlang')=='en'?'Clear':'Limpiar'}}
            </b-button>
          </b-input-group-append>
        </b-input-group>
       
      </b-form-group>

        </div>
        </b-card-header>
        
        <b-table
         :busy="isBusy"
         :items="items"
         :fields="fields"
         responsive
        striped
        hover
         selectable
      select-mode="range"
       @row-clicked="ImageDetails"
       :per-page="perPage"
      :current-page="currentPage"
          :filter="filter"
        :filter-included-fields="filterOn"
        :filter-ignored-fields="['image']"
            @filtered="onFiltered">
         <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
       <template #cell(QuestDescription)="data">
        <div
          v-html="data.value"
        />
      </template>
        </b-table>
         <b-col
      cols="12"
    >
       <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="md"
              class="my-0 mt-1 mb-1"
            />
    </b-col>
       </b-card>
        <b-modal
        v-model="openmodal"
      id="modal-1"
      title="Image Detail"
      ok-title="Validate"
      @ok="saveVerifyImg"
          ok-only
    >
    <b-card-title v-if="datamodal.item">{{datamodal.item.title}}</b-card-title>

     <b-img :src="datamodal.image" fluid alt="Responsive image" v-if="!datamodal.url"/>
     <span v-if="datamodal.url"><a :href="datamodal.url" target="_blank">{{datamodal.url}}</a></span>
    </b-modal>
    </div>
     <div v-if="isvalidateimg" class=" text-center">
   <b-spinner
        style="width: 3rem; height:3rem;"
        class="text-success"
      />
</div>
    </div>
</template>
<script>
import {BCard,BCardHeader,BCardTitle,BCardBody,BCardText,BTable,BSpinner,BAvatar,BModal,BImg,BAlert,
BRow,BCol,BFormInput,BFormGroup,BInputGroup,BInputGroupAppend,BButton,BPagination} from "bootstrap-vue"
import SellerService from '@/services/SellerService'
import vSelect from 'vue-select'
export default {
    components:{BCard,BCardHeader,BCardTitle,BCardBody,BTable,BSpinner,BAvatar,BModal,BCardText,BImg,BAlert,
    BRow,BCol,BFormInput,BFormGroup,BInputGroup,BInputGroupAppend,BButton,BPagination,vSelect},
    data(){
        return{
            isBusy:true,
            isvalidateimg:false,
            openmodal:false,
            fields:[{label:this.$t('curlang')=='en'?'Quest':'Desafio',key:'Quest'},
            {label:this.$t('curlang')=='en'?'Description':'Descripcion',key:'QuestDescription'},
            {label:this.$t('curlang')=='en'?'Challenge':'Reto',key:'item.title'},
            {label:this.$t('curlang')=='en'?'Status':'Estado',key:'status'},
            {label:this.$t('curlang')=='en'?'User':'Usuario',key:'username'},
            {label:this.$t('curlang')=='en'?'Date':'Fecha',key:'date'}],
            items:[],
            datamodal:[],
          perPage: 25,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      selected:{title:'Crowns',value:1},
      options:[{title:'Crowns',value:1},{title:'Goblets',value:2},{title:'All',value:3}]
        }
    },
    mounted(){
        this.getImages()
    },
    watch:{
      selected:function(){
        this.getImages()
      }
    },
    methods:{
         onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
        getImages:async function(){
          this.items=[]
            const vimg=(await SellerService.getValidateImages(JSON.parse(localStorage.getItem("auth")).discordId,this.selected.value)).data
           this.items=vimg
             this.totalRows = this.items.length
           this.isBusy=false
        },
        ImageDetails: function(item){
          // const im=(await SellerService.getImagebyItem(JSON.parse(localStorage.getItem("auth")).discordId,item._id)).data
            this.datamodal=[]
            this.datamodal=item
            this.datamodal["image"]="/api/v1/gxw/svimgpr/"+JSON.parse(localStorage.getItem("auth")).discordId+"/"+item._id

            this.openmodal=true
        },
        saveVerifyImg:function(){
            this.$swal({
        title: 'Are you sure to approve this Image?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Validate!',
        cancelButtonText:'No, Invalidate',
        customClass: {
          confirmButton: 'btn btn-outline-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
           this.isvalidateimg=true
           const ins=(await SellerService.approveImg(JSON.parse(localStorage.getItem("auth")).discordId,this.datamodal._id,
            1,this.$t('curlang')))
            this.getImages()
            this.isvalidateimg=false
          this.$swal({
            icon: 'success',
            title: '',
            text: 'Your Verify is Completed',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
        else if (result.dismiss === 'cancel') {
                this.isvalidateimg=true
           const del=(await SellerService.approveImg(JSON.parse(localStorage.getItem("auth")).discordId,this.datamodal._id,
            2,this.$t('curlang')))
             this.getImages()
                   this.isvalidateimg=false
          this.$swal({
            title: 'Request not Approve',
            text: 'The user reward is already deleted',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
        // this.$router.go(0)
      })
        }
        
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>